import styled from "@emotion/styled";
import React, { FC } from "react";
import { useResponsive } from "../hooks/useResponsive";
import { breakpoints } from "../styling/theme";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)<{
  fullWindowHeight: boolean;
  backgroundColor: string;
  customMinHeight: string;
  overflow: "visible" | "hidden";
}>(({ fullWindowHeight, backgroundColor, customMinHeight, overflow }) => ({
  position: "relative",
  minHeight: fullWindowHeight ? "100vh" : customMinHeight,
  background: backgroundColor,
  width: "100vw",
  overflow,
  zIndex: 1,
}));

type ContentBlockWrapperT = {
  backgroundColor: string;
  fullHeight: boolean;
  customMinHeight?: string;
  overflow?: "visible" | "hidden";
};

const ContentBlockWrapper: FC<ContentBlockWrapperT> = ({
  fullHeight,
  backgroundColor = "transparent",
  customMinHeight = "auto",
  overflow = "visible",
  children,
}) => {
  const { breakePointCondition } = useResponsive(breakpoints.md);

  return (
    <Wrapper
      fullWindowHeight={breakePointCondition ? fullHeight : false}
      customMinHeight={customMinHeight}
      backgroundColor={backgroundColor}
      overflow={overflow}
      flexDirection={breakePointCondition ? "row" : "column"}
    >
      {children}
    </Wrapper>
  );
};

export default ContentBlockWrapper;
