import styled from "@emotion/styled";
import React, { FC, useContext } from "react";
import { useResponsive } from "../../hooks/useResponsive";
import { rem, theme } from "../../styling/theme";
import ContentBlockWrapper from "../../ui/ContentBlockWrapper";
import FlexBox from "../../ui/FlexBox";
import { AppHelperContext } from "../../utils/AppHelperProvider";
import { GridItemComponentT } from "../../utils/types";

const ItemsWrapper = styled(FlexBox)({
  position: "relative",
});

const PositionWrapper = styled(FlexBox)<{
  xCor: number;
  yCor: number;
  zCor: number;
}>(({ xCor, yCor, zCor }) => ({
  position: "absolute",
  left: `${xCor}%`,
  top: `${yCor}%`,
  zIndex: zCor,
  transform: "translate(-50%, -50%)",
  transition: "all 1s cubic-bezier(0.22, 1, 0.36, 1)",

  ["&:hover"]: {
    transform: "translate(-50%, -50%) scale(1.05);",
  },

  [theme.media.maxMd]: {
    position: "relative",
    margin: rem(4),
    left: "auto",
    top: "auto",
    transform: "none",

    ["&:hover"]: {
      transform: "none",
    },
  },
}));

const StyledImage = styled.img({
  width: "100%",
  maxWidth: rem(350),
  maxHeight: rem(430),

  [theme.media.maxSm]: {
    margin: "2%",
  },
});

type GridItemsBlockT = {
  items: GridItemComponentT[];
  backgroundColor: string;
  fullHeight: boolean;
  customMinHeight?: string;
  lightBoxOption?: boolean;
};

const GridItemsBlock: FC<GridItemsBlockT> = ({
  items,
  backgroundColor,
  fullHeight,
  customMinHeight = rem(700),
  lightBoxOption = false,
}) => {
  const { setLightBoxState } = useContext(AppHelperContext);
  const { tabletVersion } = useResponsive();

  const getContent = (): JSX.Element => {
    return (
      <>
        {items.map((item) => {
          return (
            <PositionWrapper
              xCor={item.x_cor}
              yCor={item.y_cor}
              zCor={item.z_cor}
              key={item.id}
              onClick={
                lightBoxOption
                  ? () => {
                      setLightBoxState([
                        {
                          url: item.media.data.attributes.url,
                          description: item.description,
                        },
                      ]);
                    }
                  : undefined
              }
            >
              <StyledImage src={item.media.data.attributes.url} />
            </PositionWrapper>
          );
        })}
      </>
    );
  };

  return (
    <ContentBlockWrapper
      fullHeight={fullHeight}
      backgroundColor={backgroundColor}
      overflow="hidden"
      customMinHeight={customMinHeight}
    >
      {tabletVersion ? (
        <ItemsWrapper fullHeight={fullHeight} fullWidth={true} flexWrap="wrap">
          {getContent()}
        </ItemsWrapper>
      ) : (
        getContent()
      )}
    </ContentBlockWrapper>
  );
};

export default GridItemsBlock;
